import "../../../styles/footer.css";
import api from "../../../services/api";
import { useMemo, useState } from "react";

export default function Footer() {
  const [footer, setFooter] = useState([]);

  const getFooter = async () => {
    try {
      const res = await api.get(`/footer`);
      if (res.data.err !== true) {
        console.info("Dados carregados.");
        setFooter(res.data.res[0]);
        return res.data.res[0];
      } else {
        console.log("Erro ao carregar dados. Tentando novamente...");
        throw new Error("Erro ao carregar dados.");
      }
    } catch (err) {
      try {
        const res = await api.get(`/footer`);
        if (res.data.err !== true) {
          console.info("Dados carregados.");
          setFooter(res.data.res[0]);
          return res.data.res[0];
        } else {
          console.log("Erro ao carregar dados. Tentando novamente...");
          throw new Error("Erro ao carregar dados.");
        }
      } catch (finalError) {
        console.log("Erro ao carregar dados. recarregue a página" + finalError);
        return finalError;
      }
    }
  };

  useMemo(() => {
    getFooter();
  }, []);

  return (
    <section className="main-footer" id="ancoraRodape">
      <div className="footer">
        <div className="footer-div">
          <div className="div-list">
            <ul className="list">
              <h2>Atendimento</h2>
              <li>
                <img
                  src="/icons/Atendimento.svg"
                  alt="logo localização"
                  className="svg-footer"
                />
                {footer.HORARIO_ESIC != undefined && footer.HORARIO_ESIC}
              </li>

              <li>
                <img
                  src="/icons/envelope.svg"
                  alt="logo email"
                  className="svg-footer img-aten"
                />
                {footer.SITE_EMAIL_FALECOMNOSCO}
              </li>
              <li>
                <img
                  src="/icons/telephone.svg"
                  alt="logo contato"
                  className="svg-footer img-aten"
                />
                {footer.TELEFONE_EESIC}
              </li>
            </ul>

            <ul className="list">
              <h2>
                Localização -
                <a
                  target="_blank"
                  href="https://maps.app.goo.gl/T9xRNtMet8Ew3vjy7"
                  rel="noreferrer"
                >
                  Localize
                </a>
              </h2>
              <li>
                <img
                  src="/icons/geo-alt.svg"
                  alt="logo localização"
                  className="svg-footer"
                />
                {footer.ENDERECO} - {footer.BAIRRO}
              </li>
              <li>
                <img
                  src="/icons/MapaB.svg"
                  alt="logo MAPA"
                  className="svg-footer"
                />
                {footer.CIDADE}-{footer.UF} - CEP: {footer.CEP}
              </li>
              <li>
                <img
                  src="/icons/layers.svg"
                  alt="logo CNPJ"
                  className="svg-footer"
                />
                CNPJ: {footer.CNPJ}
              </li>
            </ul>

            <ul className="list">
              <h2>
                E-SIC -
                <a
                  target="_blank"
                  href="https://maps.app.goo.gl/T9xRNtMet8Ew3vjy7"
                  rel="noreferrer"
                >
                  Localize
                </a>
              </h2>
              <li>
                <img
                  src="/icons/geo-alt.svg"
                  alt="logo localização"
                  className="svg-footer"
                />
                {footer.ENDERECO} - {footer.BAIRRO}
              </li>
              <li>
                <img
                  src="/icons/envelope.svg"
                  alt="logo email"
                  className="svg-footer"
                />
                {footer.EMAIL_ESIC}
              </li>
            </ul>

            <ul className="list">
              <h2>
                Ouvidoria -
                <a
                  target="_blank"
                  href="https://maps.app.goo.gl/T9xRNtMet8Ew3vjy7"
                  rel="noreferrer"
                >
                  Localize
                </a>
              </h2>
              <li>
                <img
                  src="/icons/geo-alt.svg"
                  alt="logo localização"
                  className="svg-footer"
                />
                {footer.ENDERECO} - {footer.BAIRRO}
              </li>
              <li>
                <img
                  src="/icons/envelope.svg"
                  alt="logo email"
                  className="svg-footer"
                />
                {footer.EMAIL_OUVIDORIA}
              </li>
            </ul>
          </div>

          <div className="card-social">
            {footer.LINK_WHATSAPP != undefined &&
              footer.LINK_WHATSAPP != "" && (
                <a
                  href={footer.LINK_WHATSAPP}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link-social"
                >
                  <img
                    src="/icons/whatsapp.svg"
                    alt="logo WhatsApp"
                    className="svg-footer-social"
                    title="WhatsApp"
                  />
                  WhatsApp
                </a>
              )}

            {footer.LINK_INSTAGRAM != undefined &&
              footer.LINK_INSTAGRAM != "" && (
                <a
                  href={footer.LINK_INSTAGRAM}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link-social"
                >
                  <img
                    src="/icons/instagramWhite.svg"
                    alt="logo Instagram"
                    className="svg-footer-social"
                    title="Instagram"
                  />
                  Instagram
                </a>
              )}

            {footer.LINK_FACEBOOK != undefined &&
              footer.LINK_FACEBOOK != "" && (
                <a
                  href={footer.LINK_FACEBOOK}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link-social"
                >
                  <img
                    src="/icons/facebookWhite.svg"
                    alt="logo facebook"
                    className="svg-footer-social"
                    title="facebook"
                  />
                  Facebook
                </a>
              )}
            {/* <a
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link-social"
                >
                  <img
                    src="/icons/youtube.svg"
                    alt="logo youtube"
                    className="svg-footer-social"
                    title="youtube"
                  />
                  Youtube
                </a> */}
          </div>

          <div className="link-end-footer">
            <p>
              <a
                href="https://www.instagram.com/workcenter.consultoria"
                target="_blank"
                rel="noopener noreferrer"
              >
                Workcenter {"  "}
              </a>
              &copy; {new Date().getFullYear()}. E todos os direitos reservados.
            </p>
          </div>

          {/* <br />
            <h2 style={{ textAlign: "center" }}>SESSÕES 2º SEMESTRE:</h2>
            <div className="div-list">
              <ul>
                <h2>Agosto 2023:</h2>
                <li> Dia 07, segunda-feira as 8h </li>
                <li> Dia 14, segunda-feira as 8h </li>
                <li> Dia 21, segunda-feira as 8h </li>
                <li> Dia 28, segunda-feira as 8h </li>
              </ul>

              <ul>
                <h2>Setembro 2023:</h2>
                <li>Dia 02, segunda-feira as 8h</li>
                <li>Dia 09, segunda-feira as 8h</li>
                <li>Dia 16, segunda-feira as 8h</li>
                <li>Dia 23, segunda-feira as 8h </li>
              </ul>
              <ul>
                <h2>Outubro 2023:</h2>
                <li>Dia 04, segunda-feira as 8h</li>
                <li>Dia 11, segunda-feira as 8h</li>
                <li>Dia 18, segunda-feira as 8h</li>
                <li>Dia 25, segunda-feira as 8h </li>
              </ul>
              <ul>
                <h2>Novembro 2023:</h2>
                <li>Dia 06, segunda-feira as 8h</li>
                <li>Dia 13, segunda-feira as 8h</li>
                <li>Dia 20, segunda-feira as 8h</li>
                <li>Dia 27, segunda-feira as 8h </li>
              </ul>
              <ul>
                <h2>Dezembro 2023:</h2>
                <li>Dia 04, segunda-feira as 8h</li>
                <li>Dia 05, segunda-feira as 8h</li>
                <li>Dia 14, segunda-feira as 8h</li>
                <li>Dia 15, segunda-feira as 8h </li>
              </ul>
            </div> */}
        </div>
      </div>
    </section>
  );
}
